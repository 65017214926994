import _objectSpread from "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import service from '@/utils/request';
/**
 * 霸王餐省市区
 */

export var reqBwcRegions = function reqBwcRegions(params) {
  return service({
    url: '/bwc/show/regions',
    method: 'get',
    params: params
  });
};
/**
 * 霸王餐专场列表
 * @param {Object} { name, address, page, page_size, exist_sell_out: 是否存在红包售罄店铺 }
 */

export var reqBwcShowList = function reqBwcShowList(params) {
  return service({
    url: '/bwc/show/list',
    method: 'get',
    params: params
  });
};
/**
 * 霸王餐店铺列表
 * @param {Object} { longitude, latitude, page_index, page_size, in_activity: 是否参加活动 }
 */

export var reqBwcShopList = function reqBwcShopList(params, options) {
  return service(_objectSpread({
    url: '/bwc/show/shops',
    method: 'get',
    params: params
  }, options));
};
/**
 * 霸王餐专场创建/保存
 * @param {Object} data { id?, name, banner, color, longitude, latitude, address, show_more, location_way, items }
 */

export var reqSaveBwcShow = function reqSaveBwcShow(data) {
  return service({
    url: !data.id ? '/bwc/show/add' : '/bwc/show/update',
    method: 'post',
    data: data
  });
};
/**
 * 霸王餐专场详情
 * @param {Object} params { id }
 */

export var reqBwcShowDetail = function reqBwcShowDetail(params) {
  return service({
    url: '/bwc/show/detail',
    method: 'get',
    params: params
  });
};
/**
 * 霸王餐专场删除
 * @param {Object} data { id }
 */

export var reqDeleteBwcShow = function reqDeleteBwcShow(data) {
  return service({
    url: '/bwc/show/del',
    method: 'post',
    data: data
  });
};
/**
 * 霸王餐专场获取推广链接
 * @param {Object} data { id }
 */

export var reqGetBwcShowLink = function reqGetBwcShowLink(data) {
  return service({
    url: '/bwc/show/takelink',
    method: 'post',
    data: data
  });
};